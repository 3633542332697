import React, { useContext } from 'react';
import {
	Avatar,
	AvatarBadge,
	Badge,
	Box,
	Button,
	ButtonGroup,
	Flex,
	Heading,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Spacer,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import ColorModeSwitcher from './ColorModeSwitcher';
import { AuthContext } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { EmailIcon, HamburgerIcon, StarIcon } from '@chakra-ui/icons';
import { useAppDispatch } from '../hook';

const NavBar: React.FC = () => {
	const colorMode = useColorModeValue('light', 'dark');
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const { user, logout } = useContext(AuthContext);

	const handleLogin = () => {
		navigate('login');
	};

	// const handleFlashCardRedirect = () => {
	// 	navigate('flash-cards');
	// };

	const handleLogout = async () => {
		if (logout) {
			await Promise.resolve(logout());
		}
	};

	return (
		<Flex
			minWidth="max-content"
			alignItems="center"
			gap="2"
			p={2}
			background={colorMode === 'light' ? 'white' : 'gray.800'}
		>
			<Box ml={2}>
				<Link to="/">
					<Heading size="md">tinyneural</Heading>
				</Link>
				{/* <Text fontSize="xs" color="gray.500">
					pre-alpha
				</Text> */}
			</Box>
			<Spacer />
			{user.isAuthenticated ? (
				<Menu>
					{user.first_name !== '' && user.last_name !== '' ? (
						<Avatar
							size="sm"
							name={user.first_name + ' ' + user.last_name}
						>
							<AvatarBadge
								boxSize="1.25em"
								bg={
									user.isAuthenticated
										? 'green.500'
										: 'red.500'
								}
							/>
						</Avatar>
					) : (
						<Avatar size="sm" name={user.email || ''}>
							<AvatarBadge
								boxSize="1.25em"
								bg={
									user.isAuthenticated
										? 'green.500'
										: 'red.500'
								}
							/>
						</Avatar>
					)}
					<MenuList>
						{/* <MenuItem>My Account</MenuItem>
						<MenuDivider /> */}
						<MenuItem
							onClick={async () => {
								if (logout) {
									await Promise.resolve(logout());
								}
							}}
						>
							Logout
						</MenuItem>
					</MenuList>
				</Menu>
			) : (
				// <ButtonGroup gap="2">
				// 	<Button
				// 		leftIcon={<StarIcon />}
				// 		colorScheme="purple"
				// 		size="xs"
				// 		onClick={() => navigate('waitlist')}
				// 	>
				// 		Join Waitlist
				// 	</Button>
				// 	{/* TODO: Update size based on device */}
				// 	<Button colorScheme="teal" size="xs" onClick={handleLogin}>
				// 		Log in
				// 	</Button>
				// </ButtonGroup>
				<></>
			)}
			<ColorModeSwitcher />
		</Flex>
	);
};

export default NavBar;
