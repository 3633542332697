import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface UserState {
	username: string | undefined;
	isAuthenticated: boolean;
	slug?: string;
	first_name?: string;
	last_name?: string;
	email?: string;
	mobile?: string;
}

export const userInitialState: UserState = {
	username: undefined,
	isAuthenticated: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState: userInitialState,
	reducers: {
		updateUser: (state, action: PayloadAction<UserState>) => {
			state.username = action.payload.username;
			state.isAuthenticated = true;
			state.slug = action.payload.slug;
			state.first_name = action.payload.first_name;
			state.last_name = action.payload.last_name;
			state.email = action.payload.email;
			state.mobile = action.payload.mobile;
		},
		updateAuthenticationState: (state, action: PayloadAction<boolean>) => {
			state.isAuthenticated = action.payload;
		},
		resetUser: (state) => {
			state.username = undefined;
			state.isAuthenticated = false;
			state.slug = undefined;
			state.first_name = undefined;
			state.last_name = undefined;
			state.email = undefined;
			state.mobile = undefined;
		},
	},
});

export const { updateUser, resetUser, updateAuthenticationState } =
	userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectIsAuthenticated = (state: RootState) =>
	state.user.isAuthenticated;

export default userSlice.reducer;
