import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';

const TermsConditions: React.FC = () => {
	return (
		<>
			<Box padding={5}>
				<Heading textAlign="center">Terms and conditions</Heading>
				<Text>
					These terms and conditions (&#8220;Agreement&#8221;) set
					forth the general terms and conditions of your use of the
					www.tinyneural.com website (&#8220;Website&#8221;),
					&#8220;tinyneural&#8221; mobile application (&#8220;Mobile
					Application&#8221;) and any of their related products and
					services (collectively, &#8220;Services&#8221;). This
					Agreement is legally binding between you
					(&#8220;User&#8221;, &#8220;you&#8221; or
					&#8220;your&#8221;) and this Website operator and Mobile
					Application developer (&#8220;Operator&#8221;,
					&#8220;we&#8221;, &#8220;us&#8221; or &#8220;our&#8221;). If
					you are entering into this agreement on behalf of a business
					or other legal entity, you represent that you have the
					authority to bind such entity to this agreement, in which
					case the terms &#8220;User&#8221;, &#8220;you&#8221; or
					&#8220;your&#8221; shall refer to such entity. If you do not
					have such authority, or if you do not agree with the terms
					of this agreement, you must not accept this agreement and
					may not access and use the Services. By accessing and using
					the Services, you acknowledge that you have read,
					understood, and agree to be bound by the terms of this
					Agreement. You acknowledge that this Agreement is a contract
					between you and the Operator, even though it is electronic
					and is not physically signed by you, and it governs your use
					of the Services.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Accounts and membership
				</Text>
				<Text>
					You must be at least 13 years of age to use the Services. By
					using the Services and by agreeing to this Agreement you
					warrant and represent that you are at least 13 years of age.
					If you create an account on the Services, you are
					responsible for maintaining the security of your account and
					you are fully responsible for all activities that occur
					under the account and any other actions taken in connection
					with it. We may, but have no obligation to, monitor and
					review new accounts before you may sign in and start using
					the Services. Providing false contact information of any
					kind may result in the termination of your account. You must
					immediately notify us of any unauthorized uses of your
					account or any other breaches of security. We will not be
					liable for any acts or omissions by you, including any
					damages of any kind incurred as a result of such acts or
					omissions. We may suspend, disable, or delete your account
					(or any part thereof) if we determine that you have violated
					any provision of this Agreement or that your conduct or
					content would tend to damage our reputation and goodwill. If
					we delete your account for the foregoing reasons, you may
					not re-register for our Services. We may block your email
					address and Internet protocol address to prevent further
					registration.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Links to other resources
				</Text>
				<Text>
					Although the Services may link to other resources (such as
					websites, mobile applications, etc.), we are not, directly
					or indirectly, implying any approval, association,
					sponsorship, endorsement, or affiliation with any linked
					resource, unless specifically stated herein. We are not
					responsible for examining or evaluating, and we do not
					warrant the offerings of, any businesses or inBoxiduals or
					the content of their resources. We do not assume any
					responsibility or liability for the actions, products,
					services, and content of any other third parties. You should
					carefully review the legal statements and other conditions
					of use of any resource which you access through a link on
					the Services. Your linking to any other off-site resources
					is at your own risk.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Prohibited uses
				</Text>
				<Text>
					In addition to other terms as set forth in the Agreement,
					you are prohibited from using the Services or Content: (a)
					for any unlawful purpose; (b) to solicit others to perform
					or participate in any unlawful acts; (c) to violate any
					international, federal, provincial or state regulations,
					rules, laws, or local ordinances; (d) to infringe upon or
					violate our intellectual property rights or the intellectual
					property rights of others; (e) to harass, abuse, insult,
					harm, defame, slander, disparage, intimidate, or
					discriminate based on gender, sexual orientation, religion,
					ethnicity, race, age, national origin, or disability; (f) to
					submit false or misleading information; (g) to upload or
					transmit viruses or any other type of malicious code that
					will or may be used in any way that will affect the
					functionality or operation of the Services, third party
					products and services, or the Internet; (h) to spam, phish,
					pharm, pretext, spider, crawl, or scrape; (i) for any
					obscene or immoral purpose; or (j) to interfere with or
					circumvent the security features of the Services, third
					party products and services, or the Internet. We reserve the
					right to terminate your use of the Services for violating
					any of the prohibited uses.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Limitation of liability
				</Text>
				<Text>
					To the fullest extent permitted by applicable law, in no
					event will the Operator, its affiliates, directors,
					officers, employees, agents, suppliers or licensors be
					liable to any person for any indirect, incidental, special,
					punitive, cover or consequential damages (including, without
					limitation, damages for lost profits, revenue, sales,
					goodwill, use of content, impact on business, business
					interruption, loss of anticipated savings, loss of business
					opportunity) however caused, under any theory of liability,
					including, without limitation, contract, tort, warranty,
					breach of statutory duty, negligence or otherwise, even if
					the liable party has been advised as to the possibility of
					such damages or could have foreseen such damages. To the
					maximum extent permitted by applicable law, the aggregate
					liability of the Operator and its affiliates, officers,
					employees, agents, suppliers and licensors relating to the
					services will be limited to an amount no greater than one
					dollar or any amounts actually paid in cash by you to the
					Operator for the prior one month period prior to the first
					event or occurrence giving rise to such liability. The
					limitations and exclusions also apply if this remedy does
					not fully compensate you for any losses or fails of its
					essential purpose.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Indemnification
				</Text>
				<Text>
					You agree to indemnify and hold the Operator and its
					affiliates, directors, officers, employees, agents,
					suppliers and licensors harmless from and against any
					liabilities, losses, damages or costs, including reasonable
					attorneys&#8217; fees, incurred in connection with or
					arising from any third party allegations, claims, actions,
					disputes, or demands asserted against any of them as a
					result of or relating to your Content, your use of the
					Services or any willful misconduct on your part.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Severability
				</Text>
				<Text>
					All rights and restrictions contained in this Agreement may
					be exercised and shall be applicable and binding only to the
					extent that they do not violate any applicable laws and are
					intended to be limited to the extent necessary so that they
					will not render this Agreement illegal, invalid or
					unenforceable. If any provision or portion of any provision
					of this Agreement shall be held to be illegal, invalid or
					unenforceable by a court of competent jurisdiction, it is
					the intention of the parties that the remaining provisions
					or portions thereof shall constitute their agreement with
					respect to the subject matter hereof, and all such remaining
					provisions or portions thereof shall remain in full force
					and effect.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Dispute resolution
				</Text>
				<Text>
					The formation, interpretation, and performance of this
					Agreement and any disputes arising out of it shall be
					governed by the substantive and procedural laws of
					Karnataka, India without regard to its rules on conflicts or
					choice of law and, to the extent applicable, the laws of
					India. The exclusive jurisdiction and venue for actions
					related to the subject matter hereof shall be the courts
					located in Karnataka, India, and you hereby submit to the
					personal jurisdiction of such courts. You hereby waive any
					right to a jury trial in any proceeding arising out of or
					related to this Agreement. The United Nations Convention on
					Contracts for the International Sale of Goods does not apply
					to this Agreement.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Changes and amendments
				</Text>
				<Text>
					We reserve the right to modify this Agreement or its terms
					related to the Services at any time at our discretion. When
					we do, we will send you an email to notify you. We may also
					provide notice to you in other ways at our discretion, such
					as through the contact information you have provided.
				</Text>
				<Text>
					An updated version of this Agreement will be effective
					immediately upon the posting of the revised Agreement unless
					otherwise specified. Your continued use of the Services
					after the effective date of the revised Agreement (or such
					other act specified at that time) will constitute your
					consent to those changes.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Acceptance of these terms
				</Text>
				<Text>
					You acknowledge that you have read this Agreement and agree
					to all its terms and conditions. By accessing and using the
					Services you agree to be bound by this Agreement. If you do
					not agree to abide by the terms of this Agreement, you are
					not authorized to access or use the Services.
				</Text>
				<Text fontSize="2xl" fontWeight="bold" textAlign="center">
					Contacting us
				</Text>
				<Text>
					If you have any questions, concerns, or complaints regarding
					this Agreement, we encourage you to contact us using the
					details below:
				</Text>
				<Text>contact@tinyneural.com</Text>
				<Text>This document was last updated on Nov 13, 2023</Text>
			</Box>
		</>
	);
};

export default TermsConditions;
