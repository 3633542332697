import { extendTheme, useColorMode } from '@chakra-ui/react';

const colors = {
	brand: {},
	styles: {
		global: (props: any) => ({
			// 'html, body': {
			// 	bg: props.colorMode === 'dark' ? 'gray.800' : 'gray.50',
			// },
		}),
	},
};

export const theme = extendTheme({ colors });
