import React, { PropsWithChildren } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import AuthProvider from './AuthProvider';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Layout from './components/Layout';
import Login from './pages/Login';
import Register from './pages/Register';
import Privacy from './pages/Privacy';
import TermsConditions from './pages/TermsConditions';
import WaitList from './pages/WaitList';
import ContactUs from './pages/ContactUs';
import LandingPage from './pages/LandingPage';

export interface AuthProviderOptions {
	children: React.ReactNode;
	redirectURL: string;
}

// const AuthProviderRedirectCallback = ({
// 	children,
// }: PropsWithChildren<AuthProviderOptions>) => {
// 	return <AuthProvider>{children}</AuthProvider>;
// };

function App() {
	return (
		<ChakraProvider theme={theme}>
			{/* <AuthProviderRedirectCallback redirectURL={window.location.origin}> */}
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<LandingPage />} />
					{/* <Route path="login" element={<Login />} /> */}
					{/* <Route path="register" element={<Register />} /> */}
					{/* <Route path="waitlist" element={<WaitList />} /> */}
					{/* <Route path="contact-us" element={<ContactUs />} /> */}
					{/* <Route path="previous-year-question-papers">
							<Route
								index
								element={
									<ProtectedRoute
										component={SubjectSelectionGrid}
									/>
								}
							/>
							<Route
								path=":subject"
								element={
									<ProtectedRoute
										component={ChapterYearSelectionGrid}
									/>
								}
							/>
							<Route
								path=":subject/practice"
								element={
									<ProtectedRoute component={QAGround} />
								}
							/>
						</Route> */}
					<Route path="privacy-policy" element={<Privacy />} />
					<Route
						path="terms-and-conditions"
						element={<TermsConditions />}
					/>
				</Route>
			</Routes>
			{/* </AuthProviderRedirectCallback> */}
		</ChakraProvider>
	);
}

export default App;
