import React, { useState, useEffect } from 'react';
import { Brain } from 'lucide-react';
import { Center, Heading } from '@chakra-ui/react';

// const NeuralAnimation = () => {
// 	const [nodes, setNodes] = useState<{ x: number; y: number }[]>([]);
// 	const [connections, setConnections] = useState<[number, number][]>([]);

// 	useEffect(() => {
// 		const newNodes = Array(10)
// 			.fill(0)
// 			.map(() => ({
// 				x: Math.random() * 100,
// 				y: Math.random() * 100,
// 			}));
// 		setNodes(newNodes);

// 		const newConnections = [];
// 		for (let i = 0; i < 15; i++) {
// 			newConnections.push([
// 				Math.floor(Math.random() * newNodes.length),
// 				Math.floor(Math.random() * newNodes.length),
// 			]);
// 		}
// 		setConnections(newConnections);
// 	}, []);

// 	return (
// 		<svg className="w-full h-64" viewBox="0 0 100 100">
// 			{connections.map(([start, end], index) => (
// 				<line
// 					key={index}
// 					x1={nodes[start].x}
// 					y1={nodes[start].y}
// 					x2={nodes[end].x}
// 					y2={nodes[end].y}
// 					stroke="#4299e1"
// 					strokeWidth="0.5"
// 				/>
// 			))}
// 			{nodes.map((node, index) => (
// 				<circle
// 					key={index}
// 					cx={node.x}
// 					cy={node.y}
// 					r="2"
// 					fill="#2b6cb0"
// 				/>
// 			))}
// 		</svg>
// 	);
// };

const LandingPage = () => {
	return (
		<Center>
			<Heading
				as="h1"
				size="xl"
				textAlign="center"
				marginTop="5%"
				marginBottom="5%"
			>
				Launching Soon!
			</Heading>
		</Center>
	);
};

export default LandingPage;
