import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

// @ts-ignore
if (document.cookie.match(/csrftoken=(\S+)/)?.length > 0) {
	axios.defaults.headers.common['X-CSRFTOKEN'] =
		// @ts-ignore
		document.cookie.match(/csrftoken=(\S+)/)[1];
}

const api_client = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const login = async (formData: any) => {
	return await api_client.post('/auth/login/', formData, {
		withCredentials: true,
	});
};

export const logout = async () => {
	return await api_client.post(
		'/auth/logout/',
		{},
		{
			withCredentials: true,
		}
	);
};

export const register = async (formData: any) => {
	return await api_client.post('/auth/register/', formData, {
		withCredentials: true,
	});
};

export const getUser = async () => {
	return await api_client.get('/auth/user/', { withCredentials: true });
};

export const joinWaitlist = async (formData: any) => {
	return await api_client.post('/join-waitlist/', formData, {
		withCredentials: true,
	});
};

export const contactUs = async (formData: any) => {
	return await api_client.post('/contact-us/', formData, {
		withCredentials: true,
	});
};
