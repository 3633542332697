import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from './hook';
import {
	UserState,
	resetUser,
	selectUser,
	updateUser,
	userInitialState,
} from './reducers/user/userSlice';
import { useNavigate } from 'react-router-dom';
import {
	login as loginCall,
	logout as logoutCall,
	register as registerCall,
	getUser,
} from './api/calls';
import { useToast } from '@chakra-ui/react';

interface AuthContextOptions {
	user: UserState;
	login?(email: string, password: string): Promise<void> | undefined;
	logout?(): Promise<void> | undefined;
	// register?(
	// 	firstName: string,
	// 	lastName: string,
	// 	email: string,
	// 	mobile: string,
	// 	password: string
	// ): Promise<void> | undefined;
	register?(email: string, password: string): Promise<void> | undefined;
}

const initialAuthContextValue: AuthContextOptions = {
	user: userInitialState,
};

export const AuthContext = createContext<AuthContextOptions>(
	initialAuthContextValue
);

const AuthProvider = ({ children }: PropsWithChildren) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const toast = useToast();
	const user = useAppSelector(selectUser);

	const retrieveUser = async () => {
		getUser()
			.then(async (response: any) => {
				if (response.status === 200) {
					if (
						response.data['data']['authenticated'] &&
						response.data['data']['user'] !== null
					) {
						dispatch(updateUser(response.data['data']['user']));
					}
				}
			})
			.catch((error) => {
				navigate('login');
			});
	};

	useEffect(() => {
		if (user.username === undefined || !user.isAuthenticated) {
			retrieveUser();
		}
	}, [user]);

	const authContext = useMemo(
		() => ({
			login: async (email: string, password: string) => {
				let formData = new FormData();
				formData.append('email', email.toLowerCase());
				formData.append('password', password);
				loginCall(formData)
					.then(async (response: any) => {
						if (response.status === 200) {
							dispatch(updateUser(response.data['data']['user']));
							toast({
								title: 'Logged in successfully',
								status: 'success',
								duration: 5000,
								isClosable: true,
							});
						}
					})
					.catch(async () => {
						toast({
							title: 'Error logging in',
							status: 'error',
							duration: 5000,
							isClosable: true,
						});
					});
			},
			logout: async () => {
				logoutCall()
					.then(async (response: any) => {
						if (response.status === 200) {
							toast({
								title: 'Logged out successfully',
								status: 'success',
								duration: 5000,
								isClosable: true,
							});
							dispatch(resetUser());
						}
					})
					.catch(async () => {
						toast({
							title: 'Error logging out',
							status: 'error',
							duration: 5000,
							isClosable: true,
						});
					});
			},
			register: async (
				// firstName: string,
				// lastName: string,
				email: string,
				// mobile: string,
				password: string
			) => {
				let formData = new FormData();

				// formData.append('first_name', firstName);
				// formData.append('last_name', lastName);
				formData.append('email', email.toLowerCase());
				formData.append('password', password);
				// formData.append('mobile', mobile);
				registerCall(formData)
					.then(async (response: any) => {
						if (response.status === 200) {
							dispatch(updateUser(response.data['data']['user']));
							toast({
								title: 'Registered successfully',
								status: 'success',
								duration: 5000,
								isClosable: true,
							});
						}
					})
					.catch(async (error) => {
						error.response.data['messages'].forEach(
							(message: string) => {
								toast({
									title: message[1],
									status: 'error',
									duration: 5000,
									isClosable: true,
								});
							}
						);
					});
			},
		}),
		[]
	);

	return (
		<AuthContext.Provider value={{ user, ...authContext }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
